import React from "react";

export const PageNotFound = () => {
  return (
    <div>
      <h1>404 Error</h1>
      <h1>Page Not Found</h1>
    </div>
  );
};
