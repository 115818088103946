import React from 'react';
import {EventDetails, Status} from "../types"

interface Props {
  event: EventDetails,
  timestamp: number | undefined,
}

export const EventSummary = ({event, timestamp}: Props) => {
  const statuses = event.participants?.flatMap(participant => participant.statuses)
    .filter(status => status.timestamp == timestamp)

  const yesCount = statuses?.filter(status => status.status === Status.Yes).length || 0
  const noCount = statuses?.filter(status => status.status === Status.No).length || 0
  const maybeCount = statuses?.filter(status => status.status === Status.Maybe).length || 0

  return (
    <div>
      <li>Yes: {yesCount}</li>
      <li>No: {noCount}</li>
      <li>Maybe: {maybeCount}</li>
    </div>
  );
}
