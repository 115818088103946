import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {AddEventDate} from "./AddEventDate";
import {AddParticipant} from "./AddParticipant";
import {EventDetails} from "../types";
import {ListParticipants} from "./ListParticipants";
import {EventTimestampSelector} from "./EventTimestampSelector";
import {EventSummary} from "./EventSummary";

interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
}

export const EventComponent = ({event, setEvent}: Props) => {
  const now = Date.now()
  const futureTimes = event.timestamps?.filter(ts => ts > now).sort()
  const ts = futureTimes?.length > 0 ? futureTimes[0] : undefined
  const [searchParams, setSearchParams] = useSearchParams();
  let queryTs = searchParams.get('ts');
  const timestamp = queryTs ? Number(queryTs) : ts;
  const setTimestamp = (timestamp: number | undefined) =>
    timestamp
      ? setSearchParams({'ts': String(timestamp)})
      : setSearchParams({});

  useEffect(() => {
    if (timestamp == undefined) {
      const futureTimes = event.timestamps?.filter(ts => ts > now).sort()
      const ts = futureTimes?.length > 0 ? futureTimes[0] : undefined
      setTimestamp(ts);
    }
  }, [event.timestamps])
  const [showArchived, setShowArchived] = useState(false);

  const toggleArchived = () => {
    setShowArchived((prev) => !prev);
  };

  if (timestamp && event.timestamps.includes(timestamp)) {
    if (event.participants?.find((participant) => participant.isArchived)) {
      return <div>
        <EventTimestampSelector event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
        <div className="participants" id="participants">
          <h3>Participants</h3>
          <ListParticipants event={event} setEvent={setEvent} timestamp={timestamp} archived={false}/>
          <AddParticipant event={event} setEvent={setEvent} timestamp={timestamp}/>
          <hr/>
          {!showArchived &&
            <h3 onClick={toggleArchived}>Archived Participants ►</h3>
          }
          {showArchived &&
            <div>
              <h3 onClick={toggleArchived}>Archived Participants ▼</h3>
              <ListParticipants event={event} setEvent={setEvent} timestamp={timestamp} archived={true}/>
            </div>
          }
        </div>
        <hr/>
        <EventSummary event={event} timestamp={timestamp}/>
        <hr/>
        <AddEventDate event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
      </div>
    }
    return <div>
      <EventTimestampSelector event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
      <div className="participants" id="participants">
        <label htmlFor="participants">Participants</label>
        <ListParticipants event={event} setEvent={setEvent} timestamp={timestamp} archived={false}/>
        <AddParticipant event={event} setEvent={setEvent} timestamp={timestamp}/>
      </div>
      <hr/>
      <EventSummary event={event} timestamp={timestamp}/>
      <hr/>
      <AddEventDate event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
    </div>
  }

  if (event.timestamps) {
    return <div>
      <AddEventDate event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
      <hr/>
      <EventTimestampSelector event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
    </div>
  }

  return <div>
    <AddEventDate event={event} setEvent={setEvent} timestamp={timestamp} setTimestamp={setTimestamp}/>
  </div>;
}
