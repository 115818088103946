import React from 'react';
import {EventDetails} from "../types"
import {ParticipantComponent} from "./ParticipantComponent";

interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
  timestamp: number | undefined,
  archived: boolean,
}

export const ListParticipants = ({event, setEvent, timestamp, archived}: Props) => {
  if (event.participants?.length == 0) {
    return <p>No Participants added.</p>
  }

  return (
    <div>
      {event.participants
        ?.filter((participant) => participant.isArchived == archived || (!archived && participant.isArchived == undefined))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(participant => (
          <ParticipantComponent event={event} setEvent={setEvent} participant={participant} timestamp={timestamp}
                                key={participant.name}/>
        ))}
    </div>
  );
}
