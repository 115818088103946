import React, {useEffect, useState} from 'react';
import {EventDetails} from "../types"
import {Button, TextField} from "@mui/material";

interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
  timestamp: number | undefined,
  setTimestamp: (timestamp: number | undefined) => void,
}

function calcDefaultTime(timestamp: number | undefined) {
  // selectedTime is UTC, but defaultTime should treat UTC as local time.
  const defaultTime = new Date();
  if (timestamp) {
    const selectedTime = new Date(timestamp);
    defaultTime.setUTCDate(defaultTime.getDate() + ((7 + selectedTime.getDay() - defaultTime.getDay()) % 7));
    defaultTime.setUTCHours(selectedTime.getHours());
    defaultTime.setMinutes(selectedTime.getMinutes());
    defaultTime.setSeconds(selectedTime.getSeconds());
    defaultTime.setMilliseconds(selectedTime.getMilliseconds());
  } else {
    defaultTime.setHours(defaultTime.getHours() + 1);
    defaultTime.setMinutes(0);
    defaultTime.setSeconds(0);
    defaultTime.setMilliseconds(0);
  }
  return defaultTime;
}

export const AddEventDate = ({event, setEvent, timestamp, setTimestamp}: Props) => {
  const [date, setDate] = useState<string>(calcDefaultTime(timestamp).toISOString().replace("Z", ""));
  useEffect(() => {
    setDate(calcDefaultTime(timestamp).toISOString().replace("Z", ""))
  }, [timestamp])

  const min = new Date();
  const max = new Date();
  max.setFullYear(max.getFullYear() + 1)


  const submitForm = async (formEvent: React.MouseEvent<HTMLButtonElement>) => {
    const dateTime = new Date(date);
    console.log(dateTime);
    if (min.getTime() > dateTime.getTime()) {
      alert("event date/time must be in the future.")
      return false;
    }

    // Preventing the page from reloading
    formEvent.preventDefault();

    const response = await fetch(`/api/event/${event.id}`, {
      method: 'PUT',
      body: `${dateTime.getTime()}`
    });
    const body = await response.json()
    console.log(response, body);
    // Do something
    setEvent(body);
    setTimestamp(dateTime.getTime())
  }

  return (
    <div>
      <TextField
        label="Add Event"
        type="datetime-local"
        value={date}
        inputProps={{
          min: min.toISOString().replace(/\:[\d.]+Z/, ""),
          max: max.toISOString().replace(/\:[\d.]+Z/, ""),
        }}
        onChange={(e) => setDate(e.target.value)}
      />
      <Button variant="text" onClick={submitForm}>Add</Button>
    </div>
  );
}
