import React, {useEffect, useState} from 'react';
import {EventDetails, EventPatch, Participant, Status} from "../types"


interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
  participant: Participant,
  timestamp: number | undefined,
}

export const ParticipantComponent = ({event, setEvent, participant, timestamp}: Props) => {
  const [status, setStatus] = useState(getStatus(participant, timestamp));
  useEffect(() => {
    setStatus(getStatus(participant, timestamp))
  }, [participant.statuses, timestamp])

  const submitForm = async (newStatus: Status) => {
    setStatus(newStatus);
    const response = await fetch(`/api/event/${event.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: participant.name,
        update: {
          timestamp: timestamp,
          status: newStatus,
        }
      } as EventPatch)
    });
    const body = await response.json()
    console.log(response, body);
    // Do something
    setEvent(body);
  }

  return (
    <div className="participant" key={participant.name}>
      <div className="participantName">{participant.name}</div>
      <div className="participantChoices">
        <input type="radio" id={participant.name + "-choiceYes"} name={participant.name + "-choice"} value="yes"
               checked={status === Status.Yes}
               onChange={(e) => submitForm(Status.Yes)}/>
        <label htmlFor={participant.name + "-choiceYes"}>Yes</label>

        <input type="radio" id={participant.name + "-choiceNo"} name={participant.name + "-choice"} value="no"
               checked={status === Status.No}
               onChange={(e) => submitForm(Status.No)}/>
        <label htmlFor={participant.name + "-choiceNo"}>No</label>

        <input type="radio" id={participant.name + "-choiceMaybe"} name={participant.name + "-choice"} value="maybe"
               checked={status === Status.Maybe}
               onChange={(e) => submitForm(Status.Maybe)}/>
        <label htmlFor={participant.name + "-choiceMaybe"}>Maybe</label>
        
        <input type="radio" id={participant.name + "-choiceArchive"} name={participant.name + "-choice"} value="archive"
               checked={status === Status.Archive}
               onChange={(e) => submitForm(Status.Archive)}/>
        <label htmlFor={participant.name + "-choiceArchive"}>Archive</label>
      </div>
    </div>
  );
}

function getStatus(participant: Participant, timestamp: number | undefined): Status | undefined {
  return participant.statuses.find(status => status.timestamp === timestamp)?.status;
}
