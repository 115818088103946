export enum Status {
  Yes = "yes",
  No = "no",
  Maybe = "maybe",
  Archive = "archive"
}

export interface DateStatus {
  timestamp: number;
  status: Status;
}

export interface Participant {
  name: string;
  isArchived: boolean;
  statuses: DateStatus[];
}

export interface EventDetails {
  id: string;
  name: string;
  timestamps: number[];
  participants?: Participant[];
}

export interface EventPatch {
  name: string;
  update: DateStatus | null;
}
