import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

export const NewEvent = () => {
  const navigate = useNavigate();
  const [state, setState] = useState('');


  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();

    const response = await fetch("/api/event", {
      method: 'POST',
      body: state
    });
    const body = await response.json()
    console.log(response, body);
    navigate(`/event/${body.id}`)
    // Do something
    console.log(event);
  }

  return (
    <form name="create-event" onSubmit={submitForm}>
      <label htmlFor="event-name" style={{display: "block"}}>Event Name/Description:</label>
      <input type="text" name="event-name" required
             value={state}
             onChange={(e) => setState(e.target.value)}/>
      <input type="submit" value="submit"/>
    </form>
  );
};

