import React, {useState} from 'react';
import {EventDetails, EventPatch, Status} from "../types"

interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
  timestamp: number | undefined,
}

export const AddParticipant = ({event, setEvent, timestamp}: Props) => {
  const [newName, setName] = useState('');
  const [newStatus, setStatus] = useState('maybe');

  const submitForm = async (formEvent: React.FormEvent<HTMLFormElement>) => {
    console.log(formEvent);
    // Preventing the page from reloading
    formEvent.preventDefault();

    if (event.participants?.find(p => p.name === newName)) {
      alert(`${newName} is already participating.`)
      return false;
    }

    const response = await fetch(`/api/event/${event.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name: newName,
        update: {
          timestamp: timestamp,
          status: newStatus,
        }
      } as EventPatch)
    });
    const body = await response.json()
    setEvent(body);
    setName("")
  }

  return (
    <form className="participant" name="create-event" onSubmit={submitForm}>
      <input type="text" required
             className="participantName"
             id="newName"
             value={newName}
             pattern="[0-9A-Za-z ]{1,25}"
             onChange={(e) => setName(e.target.value)}/>
      <span className="participantChoices">
        <input type="radio" id="choiceYes" name="choice" value="yes"
               checked={newStatus === Status.Yes}
               onChange={(e) => setStatus(e.target.value)}/>
        <label htmlFor="choiceYes">Yes</label>

        <input type="radio" id="choiceNo" name="choice" value="no"
               checked={newStatus === Status.No}
               onChange={(e) => setStatus(e.target.value)}/>
        <label htmlFor="choiceNo">No</label>

        <input type="radio" id="choiceMaybe" name="choice" value="maybe"
               checked={newStatus === Status.Maybe}
               onChange={(e) => setStatus(e.target.value)}/>
        <label htmlFor="choiceMaybe">Maybe</label>
        &nbsp;
        <input type="submit" value="Add"/>
      </span>
    </form>
  );
}
