import React from "react";
import {Link} from "react-router-dom";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const NavBar = () => {
  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Link to="/">Home</Link>
        </Grid>
        <Grid item xs={9}/>
        <Grid item xs={1}>
          <Link to="/event"><LibraryAddIcon/></Link>
        </Grid>
      </Grid>
    </Box>
  );
};
