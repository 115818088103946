import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import {NavBar} from "./components/NavBar";
import {Footer} from "./components/Footer";

import {Home} from "./pages/Home";
import {NewEvent} from "./pages/NewEvent";
import {EventPage} from "./pages/EventPage";

import {PageNotFound} from './pages/404Page';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/event" element={<NewEvent/>}/>
          <Route path="/event/:id" element={<EventPage/>}/>
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
        <hr/>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
