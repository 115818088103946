import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {EventComponent} from "../components/EventComponent";
import {PageNotFound} from "./404Page";
import {EventDetails} from "../types"

export const EventPage = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState<EventDetails | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`/api/event/${id}`);
      const newData = await response.json();
      setEventData(newData);
    };
    fetchData()
      .then(value => setLoading(false));
  }, [id]);

  if (eventData === null) {
    if (loading) {
      return <p>Loading...</p>;
    }
    return <PageNotFound/>;
  }
  return <div>
    <h1>Event: {eventData.name}</h1>
    <EventComponent event={eventData} setEvent={setEventData}/>
  </div>;
};

