import React from 'react';
import {EventDetails} from "../types"
import {FormControl, InputLabel, NativeSelect} from "@mui/material";
import {formatRelative} from 'date-fns'


interface Props {
  event: EventDetails,
  setEvent: React.Dispatch<React.SetStateAction<EventDetails | null>>,
  timestamp: number | undefined,
  setTimestamp: (timestamp: number | undefined) => void,
}

export const EventTimestampSelector = ({event, timestamp, setTimestamp}: Props) => {
  const options = event.timestamps.map(ts => ({
    value: ts,
    label: formatRelative(new Date(ts), new Date())
  }))

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="timestamp-select">
          Choose an event:
        </InputLabel>
        <NativeSelect
          value={timestamp}
          inputProps={{
            name: 'timestamp-select',
            id: 'timestamp-select',
          }}
          onChange={(e) => setTimestamp(parseInt(e?.target.value))}
        >
          {options.map(option =>
            <option value={option.value}>{option.label}</option>
          )}
        </NativeSelect>
      </FormControl>
    </div>
  );
}
